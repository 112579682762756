import { Grid, Paper } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import AppAutoComplete, { AppAutocompleteOption } from "../../../app/components/app-autocomplete";
import { useEffect } from "react";
import { getOfficesThunk } from "../../offices/offices-list/offices-list.slice";
import { changeFilterAlertedPaymetnsAct } from "../../reports/reports-view/reports.slice";
export default function AlertedPaymentsFilter() {
  const dispatch = useAppDispatch()
  const { offices, gotOffices } = useAppSelector((state) => state.offices) 

  useEffect(() => {
    if(!gotOffices) dispatch(getOfficesThunk())
  }, [])

  const changeInput = (val: {name: string, val: any}) => {
    dispatch(changeFilterAlertedPaymetnsAct({name: val.name, val: val.val._id}))
  }

  const buildOffices = (): AppAutocompleteOption[] => offices.map((office) => ({_id: office._id!, name: office.name!}))
  
  return (
    <>
      <Paper sx={{padding: 2, marginBottom: 1}}>
        <Grid container>
          <Grid item xs={6}>
            <AppAutoComplete multiple={false}  name="office" label="Oficina" options={buildOffices()} onChange={(v) => changeInput(v)}/>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}