import { Button, ButtonGroup, createTheme, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { RootState } from "../../../../app/store"
import { getCallsResume } from "../../../../utils/customer.utils"
import { AddIcCall, PhoneForwarded, PhoneMissed, Recycling, SimCardAlert, Visibility } from "@mui/icons-material"
import { ThemeProvider } from "@emotion/react"
import { RowCallAssignedCustomer } from "../../reports.state"
import { dateUTCToFriendly } from "../../../../utils/date.utils"
import { getCallLogByIdWittCallNotesRelatedThunk, logCustomerAtNotCalledThunk, recycleCustomerThunk } from "../reports.slice"

const theme = createTheme({
  components: {
    MuiTableCell: { styleOverrides: { root: { padding: '1px', minWidth: "30px" } }},
    MuiButtonGroup: { defaultProps: { size: 'small'}},
    MuiButton: { defaultProps: { size: 'small'}, styleOverrides: { root: { padding: '2px', minWidth: "30px" } }},
    MuiIconButton: { defaultProps: { size: 'small'}},
    MuiSvgIcon: { defaultProps: { fontSize: "small" } , styleOverrides: { root: { fontSize: "16px" } } },
    MuiIcon: { defaultProps: { fontSize: 'small' } }
  }
})
export default function ResultCallAssignedReports() {
  const dispatch = useAppDispatch() 
  const {filter, callAssignedCustomer} = useAppSelector((state: RootState) => state.reports)

  const resolveUser = (user: any): string => {
    if(user.length === 0) return ""
    return `${user[0].name}`
  }

  const getResumeCalLogs = (call: RowCallAssignedCustomer) => {
    console.log({call});
    
    if(call.calls.length === 0) return
    const ids = []
    const indexAnswer = call.calls.findIndex(call => call._id === 3) 
    if(indexAnswer !== -1) ids.push(...(call.calls[indexAnswer] as any).items.map((item: any) => item._id))
    const indexUnanswer = call.calls.findIndex(call => call._id === 2) 
    if(indexUnanswer !== -1)  ids.push(...(call.calls[indexUnanswer] as any).items.map((item: any) => item._id))
    console.log({ids});
    
    dispatch(getCallLogByIdWittCallNotesRelatedThunk(ids))
  }

  const recycleCustomer = (customerId: string, index: number) => {
    // eslint-disable-next-line no-restricted-globals
    if(confirm("¿Está seguro de reciclar este cliente?")) {
      dispatch(recycleCustomerThunk({customerId, typeReport: "call-customers-assigned", index}))
    }
  }

  const logCustomerAtNotCalled = ({row, index} : {row: RowCallAssignedCustomer, index: number}) => {
    // eslint-disable-next-line no-restricted-globals
    if(confirm('¿Está seguro de loguear este cliente como no llamado?')) {
      dispatch(logCustomerAtNotCalledThunk({
        customer: row._id,
        dateAssigned: row.dateAssigned,
        index,
        userId: row.user[0]._id
      }))
    }
  }

  return(
    <ThemeProvider theme={theme}>
         {filter.type === "call-customers-assigned" && callAssignedCustomer !== undefined && <>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>N</TableCell>
                <TableCell>Fecha</TableCell>
                <TableCell>Cliente</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Call</TableCell>
                <TableCell>Acciones</TableCell>
                <TableCell>Reciclar</TableCell>
                <TableCell> <SimCardAlert/> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {callAssignedCustomer.map((row, i) => {
                const resumeCalls = getCallsResume({calls: row.calls})         
                return (
                  <TableRow key={i}>
                    <TableCell> {i + 1} </TableCell>
                    <TableCell>{dateUTCToFriendly(row.dateAssigned)}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{resolveUser(row.user)}</TableCell>
                    <TableCell>
                      <ButtonGroup>
                          <Button size="small" color="secondary"> <AddIcCall/> {resumeCalls.push} </Button>
                          <Button size="small" color="success"> <PhoneForwarded/> {resumeCalls.answer} </Button>
                          <Button size="small" color="error"> <PhoneMissed/> {resumeCalls.unanswer} </Button>
                          <Button size="small" onClick={() => getResumeCalLogs(row)} color="info"> <Visibility/> </Button>
                      </ButtonGroup>
                    </TableCell>
                    <TableCell>
                      AC: {Number(resumeCalls.push) + Number(resumeCalls.answer) + Number(resumeCalls.unanswer)} <br/>
                    </TableCell>
                    <TableCell>

                      <Button onClick={() => recycleCustomer(row._id, i)} disabled={row.recycle === true} size="small" variant="outlined" color="secondary"> <Recycling /> </Button>
                    </TableCell>
                    <TableCell>
                      <Button disabled={row.notCalled} onClick={() => logCustomerAtNotCalled({row, index: i })} variant="outlined"> <SimCardAlert/> </Button>
                    </TableCell>
                  </TableRow>
                )}
              )}
            </TableBody>
          </Table>
        </TableContainer>

      </>}
    </ThemeProvider>
  )
}