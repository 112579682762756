import UsersGoalsFilters from "../features/reports/users-goals/user-goals-filters";
import UsersGoalsResults from "../features/reports/users-goals/users-goals-result";

export default function UsersGoalsView() {
  return (
    <>
     <UsersGoalsFilters/>
     <UsersGoalsResults/>
    </>
  )
}